import React, { useCallback } from 'react';
import Img from 'gatsby-image';
import type { PriceItem, Product, ProductId } from '~/ducks/stripe/types';
import Button from '~/components/input/button/button';
import QuantitySpinner from '~/components/pages/shop/QuantitySpinner/QuantitySpinner';
import { getLocalFixedImage } from '~/ducks/stripe/selectors';
import { getFormattedPrice } from '~/ducks/shopping/shopping-utils';
import style from './ProductCardItem.module.scss';

export interface Props {
  product: Product;
  priceItem: PriceItem;
  quantity: number;
  onAddClick(product: Product): void;
  setQuantity(productId: ProductId, quantity: number): void;
}

const ProductCardItem = ({
  product,
  priceItem,
  onAddClick,
  quantity,
  setQuantity,
}: Props) => {
  const localFixedImage = getLocalFixedImage(product);

  const handleAddClick = useCallback(() => {
    onAddClick(product);
  }, [onAddClick, product]);

  const onQuantityChange = useCallback(
    (quantity) => {
      setQuantity(product.id, quantity);
    },
    [product, setQuantity]
  );

  return (
    <div className={style.ProductCardItem}>
      <div className={style.imageWrapper}>
        <Img fixed={localFixedImage} />
      </div>

      <div className={style.contentWrapper}>
        <div className={style.itemInfo}>
          <h6>{product.name}</h6>
          <p>{product.description}</p>
          <p>{getFormattedPrice(priceItem.unit_amount, priceItem.currency)}</p>
        </div>
      </div>
      <div className={style.bottomWrapper}>
        {quantity > 0 ? (
          <QuantitySpinner
            displayQuantityWord={true}
            quantity={quantity}
            setQuantity={onQuantityChange}
          />
        ) : (
          <Button onClick={handleAddClick}>
            <span>Add to cart</span>
          </Button>
        )}
      </div>
    </div>
  );
};

export default ProductCardItem;
