import React, { useCallback, useEffect, useState } from 'react';
import cls from 'classnames';
import Section from '~/components/layout/section/section';
import { useCartContext } from '~/ducks/shopping/shopping-context';
import { useStripeDataContext } from '~/ducks/stripe/stripe-context';
import Product from '../Product/Product';
import RegionSelect, { Region } from './RegionSelect';
import style from './ShoppingSection.module.scss';

const regions: Region[] = [
  {
    currency: 'sek',
    name: '🇸🇪Sweden',
  },
  {
    currency: 'usd',
    name: '🌍Global',
  },
];

const ShoppingSection = () => {
  const { products } = useStripeDataContext();
  const { chosenCurrency, setChosenCurrency } = useCartContext();
  const [nextCurrency, setNextCurrency] = useState<AllowedCurrency | null>(
    null
  );

  const onCurrencyClick = useCallback(
    (currency: AllowedCurrency) => {
      if (chosenCurrency !== currency) {
        setNextCurrency(currency);
      }
    },
    [setNextCurrency, chosenCurrency]
  );

  useEffect(() => {
    if (nextCurrency) {
      const timeout = setTimeout(() => {
        setChosenCurrency(() => nextCurrency);
        setNextCurrency(null);
      }, 600);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [nextCurrency, setChosenCurrency]);

  return (
    <Section
      className={style.ShoppingSection}
      innerClassName={style.innerSection}
    >
      <div className={style.products}>
        {products.map((product) => (
          <Product key={product.id} product={product} />
        ))}
      </div>
      <RegionSelect
        regions={regions}
        chosenCurrency={chosenCurrency}
        onCurrencyClick={onCurrencyClick}
      />
      <div
        className={cls(style.loading, {
          [style.visible]: nextCurrency !== null,
        })}
      >
        <h4>Loading...</h4>
      </div>
    </Section>
  );
};

export default React.memo(ShoppingSection);
