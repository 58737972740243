import React from 'react';
import Layout from '~/components/layout/layout/layout';
import SEO from '~/components/common/SEO/SEO';
import ShoppingSection from '~/components/pages/shop/ShoppingSection/ShoppingSection';

const ShoppingPage = () => {
  return (
    <Layout>
      <SEO title="Shopping" />
      <ShoppingSection />
    </Layout>
  );
};

export default ShoppingPage;
