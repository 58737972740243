import React from 'react';
import type { Product as ProductType } from '~/ducks/stripe/types';
import { useCartContext } from '~/ducks/shopping/shopping-context';
import { useStripeDataContext } from '~/ducks/stripe/stripe-context';
import ProductCardItem from '../ProductCardItem/ProductCardItem';

export interface Props {
  product: ProductType;
}

const Product = ({ product }: Props) => {
  const { selectors } = useStripeDataContext();
  const {
    addItem,
    findCartItem,
    setQuantity,
    chosenCurrency,
  } = useCartContext();
  const cartItem = findCartItem(product.id);
  const productPriceItem = selectors.getProductPriceItem(
    product.id,
    chosenCurrency
  );

  return (
    <ProductCardItem
      product={product}
      priceItem={productPriceItem}
      quantity={cartItem?.quantity || 0}
      onAddClick={addItem}
      setQuantity={setQuantity}
    />
  );
};

export default Product;
