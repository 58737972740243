import React, { MouseEvent, useCallback } from 'react';
import cls from 'classnames';
import style from './ShoppingSection.module.scss';

export interface Region {
  currency: AllowedCurrency;
  name: string;
}

interface Props {
  regions: Region[];
  chosenCurrency: AllowedCurrency;
  onCurrencyClick(currency: AllowedCurrency): void;
}

const RegionSelect = ({ chosenCurrency, onCurrencyClick, regions }: Props) => {
  const handleOnClick = useCallback(
    (e: MouseEvent<HTMLLIElement>) => {
      onCurrencyClick(e.currentTarget.dataset.currency as AllowedCurrency);
    },
    [onCurrencyClick]
  );

  return (
    <div className={style.regionSelect}>
      <span className={cls(style.header)}>Choose shipping region:</span>

      <ul>
        {regions.map((region) => (
          <li
            key={region.currency}
            data-currency={region.currency}
            className={cls({
              [style.active]: chosenCurrency === region.currency,
            })}
            onClick={handleOnClick}
          >
            <span className={style.name}>{region.name}</span>
            <span className={style.currency}>({region.currency})</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default RegionSelect;
